@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  background-color: #FDFDFD;
  inset: 0;
  margin: 0;
  padding: 0;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
  "Oxygen", "Ubuntu", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol";
}

@layer components{
  .slider{
    @apply w-full overflow-hidden ;
  }

  .slide{
    @apply top-0 left-0 w-full h-full hidden transition-all ease-linear;
  }

  .current{
    @apply block;
  }

  .content{
    @apply absolute top-96 left-16 
  }
}


@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0
  }
  100% {
    background-position: 800px 0
  }
}

.animation{
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-color: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
  background-size: 800px 104px;
  position: relative;
}

.animation-blue{
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-color: #f6f7f8;
  background: linear-gradient(to right, #3386aa 8%, #31c0fe 18%, #3FA9D5 33%);
  background-size: 800px 104px;
  position: relative;
}
.background-masker {
  background-color: #fff;
  position: absolute;
}

.btn-divide-left {
  top: 0;
  height: 100%;
  width: 10px;
}

.btn-divide-left-h {
  top: 0;
  height: 100%;
  width: 16px;
}


.loader {
  margin: auto;
  border: 5px solid #EAF0F6;
  border-radius: 50%;
  border-top: 5px solid;
  width: 34px;
  height: 34px;
  animation: spinner 1s linear infinite;
}

@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
